/* global graphql */
import React from 'react';
import TemplateWrapper from '../components/TemplateWrapper';
import { Link } from "gatsby";

const UnknownPage = props =>
  (<TemplateWrapper location={props.location}>
    <main className="UnknownPage">
      <section className="Section">
        <div className="container-fluid">
          <div className="wrapper">
            <div className="row UnknownPage-container">
              <div className="col-xs-14 col-md-6 col-md-offset-1 UnknownPage-left">
                <h1 className="Heading Heading-d1">404</h1>
                <h2 className="Heading Heading-h2">This isn't Magical.</h2>
                <p className="BodyText BodyText-B1">The page you are looking for has either moved or has vanished.</p>
                <div className="UnknownPage-button">
                  <Link to="/" className="Btn BtnText BtnText-pink light">GO 127.0.0.1</Link>
                </div>
              </div>
              <div className="col-xs-14 col-md-5 col-md-offset-1 UnknownPage-right">
                <img src="https://s3.us-east-2.amazonaws.com/hologram-static/magic/marketing/404-blob+(1).png" alt="bau" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </TemplateWrapper>);

export default UnknownPage;

